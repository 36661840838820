import type { EventNameString } from "firebase/analytics";
import { useSiteStore } from "~/store/siteStore";

export enum PageTypes {
  LandingPage = "landingPage",
  CUSTOMIZER = "customizationPage",
}

export default function useConsentTracking() {
  const siteStore = useSiteStore();
  const {
    consentAccepted,
    emergencyConsent,
    analysisConsentGoogle,
    errorReportingConsentToSentry,
  } = storeToRefs(siteStore);
  const isVisible = computed(() => !consentAccepted.value);
  const currentPage = ref<PageTypes>(PageTypes.LandingPage);
  const navigateToCustomization = () => {
    currentPage.value = PageTypes.CUSTOMIZER;
  };
  const acceptConsent = () => {
    siteStore.acceptConsent();
  };
  const customizeConsentAccept = () => {
    acceptConsent();
  };
  const logGoogleEventWithMiddleWare = (
    eventName: EventNameString,
    params?: Record<string, string | { [key: string]: string }>,
  ) => {
    if (consentAccepted.value && analysisConsentGoogle.value) {
      useNuxtApp().$logGoogleEvent(eventName, params);
    }
  };
  return {
    isVisible,
    currentPage,
    navigateToCustomization,
    emergencyConsent,
    analysisConsentGoogle,
    errorReportingConsentToSentry,
    acceptConsent,
    customizeConsentAccept,
    logGoogleEventWithMiddleWare,
  };
}
